import i18n from '@/i18n/index'
// import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'current',
      value: false,
      name: 'switch',
      label: i18n.t('form.members.defaultAddress'),
      Object: {}
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.members.addressee'),
      Object: {}
    }, {
      span: 8,
      prop: 'phone_number',
      name: 'input',
      label: i18n.t('form.members.phoneNumber'),
      Object: {}
    }, {
      span: 8,
      prop: 'postal_code',
      name: 'input',
      label: i18n.t('form.members.postalCode'),
      Object: {}
    }, {
      span: 8,
      prop: 'road_name',
      name: 'input',
      label: i18n.t('form.members.roadName'),
      Object: {}
    }, {
      span: 8,
      prop: 'building_name',
      name: 'input',
      label: i18n.t('form.members.buildingName'),
      Object: {}
    }, {
      span: 8,
      prop: 'block_number',
      name: 'input',
      label: i18n.t('form.members.blockNumber'),
      Object: {}
    }, {
      span: 8,
      prop: 'unit_no',
      name: 'input',
      label: i18n.t('form.members.unitNo'),
      Object: {}
    }, {
      span: 8,
      prop: 'username',
      name: 'input',
      label: i18n.t('form.members.user'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true
      }
    }, {
      span: 8,
      prop: 'address_type_id',
      name: 'select',
      options: [],
      label: i18n.t('form.members.addressType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        label: 'name',
        value: 'id'
      }
    }, {
      span: 8,
      prop: 'business_type',
      name: 'select',
      options: [],
      label: i18n.t('form.members.residentialType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        label: 'label'
      }
    }, {
      span: 24,
      prop: 'address_name',
      name: 'input',
      label: i18n.t('form.members.address'),
      Object: {
        visible: 'business_type',
        visibleValue: 'business'
      }
    }]
  }]
}
