// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getAddresTypesSimpleList, getMemberAddressPreview, adderssEdit } from '@/services/members.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'onlineCouponEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      btnTxt: this.$t('button.confirm'),
      addressTypeOptions: [
        {
          label: this.$t('form.members.private'),
          value: 'private'
        },
        {
          label: this.$t('form.members.business'),
          value: 'business'
        }
      ]
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getAddresTypesSimpleList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[10].options = this.addressTypeOptions
          this.formData[0].createDateItem[9].options = res.data.address_types
        }
      })
      getMemberAddressPreview(this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.modifyData = res.data.address
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        address: obj
      }
      adderssEdit(this.$route.params.id, params).then(res => {
        if (res.status >= 200 && res.status < 300) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'memberDetail'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
